<template>
  <lf-card class="max-w-screen-md py-6">
    <loader :is-loading="isSubmitting" />
    <div class="pb-6 pt-4 space-y-5">
      <lf-h3>
        {{ $t("ORGANIZATION.SSN_MASK.BIG_TITLE") }}
      </lf-h3>
    </div>
    <div class="grid grid-cols-2">
      <div v-for="(serviceName, serviceKey) in piiMapping" :key="serviceKey">
        <lf-checkbox
          :name="`checkbox-${serviceName}`"
          class="mb-4"
          v-model="selectedServices"
          :value="Number(serviceKey)"
        >
          {{ PII_MAPPING[serviceName] }}
        </lf-checkbox>
      </div>
    </div>
    <access-control :roles="[SUPER_ADMIN, ADMIN, CLIENT_ADMIN]">
      <div
        class="flex justify-end items-center min-w-full border-t py-6 pr-5 mt-5"
      >
        <primary-button type="button" :disabled="isSubmitting" @click="submit">
          {{ $t("ORGANIZATION.API_CONFIG.SAVE_CHANGES") }}
        </primary-button>
      </div>
    </access-control>
  </lf-card>
</template>

<script lang="ts" setup>
import { computed, ref, watch } from "vue";
import { useNotification } from "@/hooks/notifications";
import { useI18n } from "vue-i18n";
import { useStore } from "vuex";
import { PII_MAPPING } from "@/helpers/constants";
import type { IClient } from "@/models/clients";
import LfCheckbox from "@/components/ui/inputs/LfCheckbox.vue";
import { SUPER_ADMIN, ADMIN, CLIENT_ADMIN } from "@/helpers/constants";

const { getters, dispatch } = useStore();

const { showMessage } = useNotification();
const { t } = useI18n();

const selectedServices = ref<number[]>([]);

const isSubmitting = ref(false);

const piiMapping = computed<Record<number, keyof typeof PII_MAPPING>>(
  () => getters["options/piiMapping"]
);
const client = computed<IClient>(() => getters["clients/active"]);

const submit = async () => {
  isSubmitting.value = true;
  try {
    await dispatch("clients/updatePiiMapping", {
      id: client.value.id,
      data: selectedServices.value
    });
  } catch {
    showMessage(t("COMMON.ERROR_OCCURRED"), "error");
  } finally {
    isSubmitting.value = false;
  }
};

watch(
  () => client.value.maskedPiis,
  (val) => {
    selectedServices.value = val.map((item) => item.id);
  },
  { immediate: true }
);
</script>
